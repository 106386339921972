import React from 'react';
import './App.css';
import './main.css';
import { Row, Col } from 'react-bootstrap';
import { Route, NavLink, HashRouter, Switch } from 'react-router-dom';
import Highlights from './Highlights';
import Publications from './Publications';
import Projects from './Projects';
import Misc from './Misc';
import Scroller from './scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFile, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faMastodon } from '@fortawesome/free-brands-svg-icons';
import { Canvil, TeachableFeedExperiences } from './assets/Projects2024.js';
import { UXIML, UXCollab, C2PAProvenance, UXAIComm, CaseRepositories } from './assets/Projects2023.js';
import { CFExp, Handoffs, ShapedWordClouds, WhatsappMisinfo } from './assets/Projects2022.js';
import { AdOculos, SansTimesSans } from './assets/Projects2021.js';
import { ARBlockbot, Rehack, GraphicDesign, Audioscape } from './assets/Projects2020etc.js';

function App() {
  return (

    <HashRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/projects/canvil" component={Canvil}/>
        <Route path="/projects/teachable-feed-experiences" component={TeachableFeedExperiences}/>
        <Route path="/projects/case-repositories" component={CaseRepositories}/>
        <Route path="/projects/provenance-social-media" component={C2PAProvenance}/>
        <Route path="/projects/ai-ux-communication" component={UXAIComm}/>
        <Route path="/projects/ux-collaboration" component={UXCollab}/>
        <Route path="/projects/ux-iml" component={UXIML}/>
        <Route path="/projects/shaped-wordclouds" component={ShapedWordClouds}/>
        <Route path="/projects/shaped-wordclouds" component={ShapedWordClouds}/>
        <Route path="/projects/handoffs-cocreation" component={Handoffs}/>
        <Route path="/projects/counterfactuals" component={CFExp}/>
        <Route path="/projects/adoculos" component={AdOculos}/>
        <Route path="/projects/whatsapp-misinfo" component={WhatsappMisinfo}/>
        <Route path="/projects/arblockbot" component={ARBlockbot}/>
        <Route path="/projects/i-n-t-e-r" component={() => {
            window.location.replace("https://i-n-t-e-r.github.io");
            return null;
          }}/>
        <Route path="/projects/sans-times-sans" component={SansTimesSans}/>
        <Route path="/projects/rehack" component={Rehack}/>
        <Route path="/projects/graphic-design" component={GraphicDesign}/>
        <Route path="/projects/audioscape" component={Audioscape}/>

        <div className="App">
          <Col md={12} lg={10} className="mainCol">

            <div style={{marginBottom: "2rem"}}>
              <h1><a href="/">Kevin Feng</a></h1>
              <p><i>— HCI researcher and PhD student</i></p>
            </div>

            <Row style={{paddingBottom: "2rem"}}>
              <Col xs={{span:12, order:2}} md={{span:6, order:1}} lg={{span:4, order:1}}>
                <div className="links-wrapper">
                  <img src={ require('./assets/img/me.png').default } alt="Smiling Asian man with cap sitting in a chair, in black and white." className="bio-image" />
                  <div className="links-block">

                    <div style={{display: "flex"}}>
                      <div className="icon-wrapper"><span className="bio-icon"><FontAwesomeIcon icon={faEnvelope} /></span></div>
                      <div>kjfeng[AT]uw[DOT]edu</div>
                    </div>
                    <div style={{display: "flex"}}>
                      <div className="icon-wrapper"><span className="bio-icon"><FontAwesomeIcon icon={faFile} /></span></div>
                      <div><a href={ require("./assets/files/cv.pdf").default }>CV (PDF)</a></div>
                    </div>
                    <div style={{display: "flex"}}>
                      <div className="icon-wrapper"><span className="bio-icon"><FontAwesomeIcon icon={faGraduationCap} /></span></div>
                      <div><a href="https://scholar.google.com/citations?user=CNrJuz8AAAAJ&hl=en">Google Scholar</a></div>
                    </div>
                    <div style={{display: "flex"}}>
                      <div className="icon-wrapper"><span className="bio-icon"><FontAwesomeIcon icon={faTwitter} /></span></div>
                      <div><a href="https://twitter.com/kjfeng_">@kjfeng_</a></div>
                    </div>
                    <div style={{display: "flex"}}>
                      <div className="icon-wrapper"><span className="bio-icon"><FontAwesomeIcon icon={faMastodon} /></span></div>
                      <div><a href="https://hci.social/@kjfeng">@kjfeng@hci.social</a></div>
                    </div>
                    
                  </div>
                  
                </div>

              </Col>

              <Col xs={{span:12, order:1}} md={{span:6, order:2}} lg={{span:8, order:2}} className="bio-wrapper">
                <p>I'm a third-year PhD student in the <a href="https://www.hcde.washington.edu/">Human Centered Design & Engineering</a> department at the University of Washington, fortunate to be co-advised by <a href='https://www.hcde.washington.edu/mcdonald'>David W. McDonald</a> (HCDE) and <a href='https://homes.cs.washington.edu/~axz/'>Amy X. Zhang</a> (CSE). My interests are at the intersection of social computing and interactive machine learning. I strive to make large-scale sociotechnical systems (e.g., social media platforms, language models) more flexible and adaptable. These days, I’m particularly interested in <i>socially-situated</i> adaptation: how can one person or group’s adaptation of a system benefit others around them?</p>

                <p>In summer 2023, I interned with the <a href="https://www.microsoft.com/en-us/research/theme/fate/">FATE group</a> at Microsoft Research Montréal, where I worked on tools for designers to better adapt language models for AI-powered user experiences. The summer before that, I interned on the Responsible AI team within Microsoft’s Azure Machine Learning division to improve <a href="https://learn.microsoft.com/en-us/azure/machine-learning/concept-responsible-ai-scorecard">model scorecards</a>. Even before that, I graduated from Princeton University with a B.S.E. in Computer Science and a minor in Visual Arts in 2021, where I was affiliated with the <a href="https://citp.princeton.edu/">CITP</a> and worked with <a href="http://aruneshmathur.co.in/">Arunesh Mathur</a> and <a href="https://www.cs.princeton.edu/~arvindn/">Arvind Narayanan</a> on tools to democratize web advertisement research at scale.</p>
              </Col>
            </Row>

              <div className="nav-wrapper" id="nav">
                <NavLink to="/" exact={true} className="navigator">Highlights</NavLink>
                <NavLink to="/publications" className="navigator">Publications</NavLink>
                <NavLink to="/projects" className="navigator">Projects</NavLink>
                <NavLink to="/misc" className="navigator">Misc</NavLink>
              </div>

              <Route exact path="/" component={Highlights}/>
              <Route path="/publications" component={Publications}/>
              <Route path="/projects" component={Projects}/>
              <Route path="/misc" component={Misc}/>


            </Col>

        </div>
      </Switch>

      <Scroller />

    </HashRouter>
  );
}

export default App;
