import React, { useEffect } from 'react';
import Showcase from './Showcase.js';

function Highlights() {
  useEffect(() => {
    sessionStorage.setItem("onDetailsPage", 0);
  }, []);

  return (
    <div className="showcase-wrapper">
      <Showcase
        thumbnail={ require('./assets/img/canvil-cover.png').default }
        title="Canvil"
        subtitle="Designerly Adaptation for LLM-Powered User Experiences"
        venue="arXiv 2024"
        urlext="canvil"
        paper="https://arxiv.org/pdf/2401.09051.pdf"
        demo="https://www.figma.com/community/widget/1277396720888327660"
      />
      <Showcase
        thumbnail={ require('./assets/img/teachable-feeds-cover.png').default }
        title="Teachable Social Media Feeds"
        subtitle="Mapping the Design Space of Teachable Social Media Feed Experiences"
        venue="CHI 2024"
        urlext="teachable-feed-experiences"
        paper="https://arxiv.org/pdf/2401.14000.pdf"
      />
      <Showcase
        thumbnail={ require('./assets/img/case-repos-cover.png').default }
        title="Democratic Inputs to AI"
        subtitle="Case-Based Reasoning for AI Alignment"
        venue="MP2 @ NeurIPS 2023"
        urlext="case-repositories"
        paper="https://arxiv.org/pdf/2311.10934.pdf"
        demo="https://social.cs.washington.edu/case-law-ai-policy/"
        poster={require("./assets/files/mp2_case_law_poster.pdf").default}
      />
       <Showcase
        thumbnail={ require('./assets/img/provenance-cover.png').default }
        title="Provenance in Social Media Feeds"
        subtitle="Examining the Impact of Provenance-Enabled Media on Trust and Accuracy Perceptions"
        venue="CSCW 2023"
        urlext="provenance-social-media"
        paper="https://arxiv.org/pdf/2303.12118.pdf"
        doi="https://dl.acm.org/doi/10.1145/3610061"
        slides={require("./assets/files/cscw2023-slides.pdf").default}
      />
      <Showcase
        thumbnail={ require('./assets/img/ux-collab-cover.png').default }
        title="Understanding Collaboration in UX"
        subtitle="Understanding Collaborative Practices and Tools of Professional UX Practitioners in Software Organizations"
        venue="CHI 2023"
        urlext="ux-collaboration"
        paper={require("./assets/files/chi23-584-f.pdf").default}
        doi="https://dl.acm.org/doi/abs/10.1145/3544548.3581273"
        slides={require("./assets/files/chi2023-slides.pdf").default}
        video="https://www.youtube.com/watch?v=C_DNePZMyMk"
      />
      <Showcase
        thumbnail={ require('./assets/img/ux-iml-cover.png').default }
        title="Interactive Machine Learning in UX Practice"
        subtitle="Addressing UX Practitioners’ Challenges in Designing ML Applications: an Interactive Machine Learning Approach"
        venue="IUI 2023"
        urlext="ux-iml"
        paper={require("./assets/files/iui23-33-f.pdf").default}
        doi="https://dl.acm.org/doi/abs/10.1145/3581641.3584064"
        slides={require("./assets/files/iui2023-slides.pdf").default}
      />
     

      {/*<img src={ require('./assets/img/construction.gif').default } style={{maxWidth: 500}}/>*/}
    </div>
  );
}

export default Highlights;
