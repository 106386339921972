import React from 'react';
import '../main.css';
import { AuthorPile, InfoPage } from './HelperComponents';
import Details from './Details';
import * as dataObjects from './dataObjects.js';

export function TeachableFeedExperiences() {

  return (
    <InfoPage helmetExt="Mapping the Design Space of Teachable Social Media Feed Experiences">

      <h2>{dataObjects.TeachableFeedExperiencesObj.title}</h2>
      <AuthorPile authors={dataObjects.TeachableFeedExperiencesObj.authors}/>
      <p><i>* equal contribution</i></p>
      <p className="venue-page">{dataObjects.TeachableFeedExperiencesObj.venue}</p>

      <h3>Abstract</h3>
      <p>Social media feeds are deeply personal spaces that reflect individual values and preferences. However, top-down, platform-wide content algorithms can reduce users' sense of agency and fail to account for nuanced experiences and values. Drawing on the paradigm of interactive machine teaching (IMT), an interaction framework for non-expert algorithmic adaptation, we map out a design space for teachable social media feed experiences to empower agential, personalized feed curation. To do so, we conducted a think-aloud study (N=24) featuring four social media platforms—Instagram, Mastodon, TikTok, and Twitter—to understand key signals users leveraged to determine the value of a post in their feed. We synthesized users' signals into taxonomies that, when combined with user interviews, inform five design principles that extend IMT into the social media setting. We finally embodied our principles into three feed designs that we present as sensitizing concepts for teachable feed experiences moving forward.</p>

      <h3>Details</h3>
      <Details {...dataObjects.TeachableFeedExperiencesObj}/>

    </InfoPage>
  );
}

export function Canvil() {

  return (
    <InfoPage helmetExt="Canvil: Designerly Adaptation for LLM-Powered User Experiences">

      <h2>{dataObjects.CanvilObj.title}</h2>
      <AuthorPile authors={dataObjects.CanvilObj.authors}/>
      <p className="venue-page">{dataObjects.CanvilObj.venue}</p>

      <h3>Abstract</h3>
      <p>Advancements in large language models (LLMs) are poised to spark a proliferation of LLM-powered user experiences. In product teams, designers are often tasked with crafting user experiences that align with user needs. To involve designers and leverage their user-centered perspectives to create effective and responsible LLM-powered products, we introduce the practice of designerly adaptation for engaging with LLMs as an adaptable design material. We first identify key characteristics of designerly adaptation through a formative study with designers experienced in designing for LLM-powered products (N=12). These characteristics are 1) have a low technical barrier to entry, 2) leverage designers' unique perspectives bridging users and technology, and 3) encourage model tinkering. Based on this characterization, we build Canvil, a Figma widget that operationalizes designerly adaptation. Canvil supports structured authoring of system prompts to adapt LLM behavior, testing of adapted models on diverse user inputs, and integration of model outputs into interface designs. We use Canvil as a technology probe in a group-based design study (6 groups, N=17) to investigate the implications of integrating designerly adaptation into design workflows. We find that designers are able to iteratively tinker with different adaptation approaches and reason about interface affordances to enhance end-user interaction with LLMs. Furthermore, designers identified promising collaborative workflows for designerly adaptation. Our work opens new avenues for collaborative processes and tools that foreground designers' user-centered expertise in the crafting and deployment of LLM-powered user experiences.</p>

      <h3>Details</h3>
      <Details {...dataObjects.CanvilObj}/>

    </InfoPage>
  );
}
